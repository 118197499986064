import React from 'react';
import defaultImg from "../images/default-profile.png";
import './css/ResultEntry.css';

const ResultEntry = ({ name, company, profile_link, profile_img, onRemove }) => {
  return (
    <div className="result-entry">
      <button 
        className="remove-button"
        onClick={onRemove}
        title="Remove from list"
      >
        ✕
      </button>
      <a
        className="result-info name"
        title={name}
        href={profile_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="profile-img"
          src={profile_img ? profile_img : defaultImg}
          alt="profile"
        ></img>
        <div>{name}</div>
      </a>
      <div className="result-info company">@{company}</div>
    </div>
  );
};

export default ResultEntry; 
import React, { useState } from 'react';
import { UserAuth } from '../contexts/AuthContext';
import { useSubscription } from '../contexts/SubscriptionContext';
import './css/SubscriptionModal.css';
import Modal from './Modal';

const SubscriptionModal = ({ isOpen, onClose, feature = 'this' }) => {
  const { user } = UserAuth();
  const { subscription, remainingEmails, remainingScrapes, freeTierLimits } = useSubscription();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cancellationLoading, setCancellationLoading] = useState(false);
  const [cancellationError, setCancellationError] = useState(null);
  const [showCancellationConfirm, setShowCancellationConfirm] = useState(false);

  // Check if user is on premium plan (regardless of cancellation status)
  const isPremium = subscription?.planType === 'premium' && subscription?.status === 'active';
  // Check if subscription is active and not pending cancellation
  const isPremiumActive = isPremium && !subscription?.cancelAtPeriodEnd;
  // Check if subscription is pending cancellation
  const isCancellationPending = isPremium && subscription?.cancelAtPeriodEnd;

  if (!isOpen) return null;

  const handleSubscribe = async () => {
    setError(null);
    setIsLoading(true);
    
    try {
      // const response = await fetch(`http://127.0.0.1:8000/create-checkout-session`, {
      const response = await fetch(`https://api.thenodes.ca/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: 'price_1Qmsd3KQPISoltxdgL3XZutk',
          userId: user.uid,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      if (url) {
        window.location.href = url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to start subscription process. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setCancellationLoading(true);
      setCancellationError(null);

      // const response = await fetch('http://127.0.0.1:8000/cancel-subscription', {
      const response = await fetch(`https://api.thenodes.ca/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.uid,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      // Close the confirmation dialog and modal
      setShowCancellationConfirm(false);
      onClose();

    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setCancellationError('Failed to cancel subscription. Please try again.');
    } finally {
      setCancellationLoading(false);
    }
  };

  const getRemainingMessage = () => {
    if (feature === 'email') {
      if (remainingEmails === -1) {
        return 'You have unlimited emails available.';
      }
      return `You have ${remainingEmails} of ${freeTierLimits.EMAILS_PER_MONTH} emails remaining this month.`;
    } else if (feature === 'scrape') {
      if (remainingScrapes === -1) {
        return 'You have unlimited scrapes available.';
      }
      return `You have ${remainingScrapes} of ${freeTierLimits.SCRAPES_PER_MONTH} scrapes remaining this month.`;
    }
    return '';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="subscription-modal">
        <h2>Manage Subscription</h2>
        {error && <div className="error-message">{error}</div>}
        {cancellationError && <div className="error-message">{cancellationError}</div>}
        <p>{getRemainingMessage()}</p>
        
        <div className="pricing-cards">
          <div className="pricing-card">
            <h3>Free</h3>
            <p className="price">$0/month</p>
            <ul>
              <li>{freeTierLimits.SCRAPES_PER_MONTH} scrapes per month</li>
              <li>{freeTierLimits.EMAILS_PER_MONTH} emails per month</li>
            </ul>
            {isPremiumActive ? (
              <button 
                className="downgrade-button"
                onClick={() => setShowCancellationConfirm(true)}
                disabled={isLoading}
              >
                Downgrade to Free
              </button>
            ) : isCancellationPending ? (
              <button 
                className="current-plan-button"
                disabled
              >
                Cancellation Pending
              </button>
            ) : (
              <button 
                className="current-plan-button"
                disabled
              >
                Current Plan
              </button>
            )}
          </div>

          <div className="pricing-card featured">
            <h3>Premium</h3>
            <p className="price">$5/month</p>
            <ul>
              <li>Unlimited scraping</li>
              <li>Unlimited email sending</li>
            </ul>
            {isPremiumActive ? (
              <button 
                className="current-plan-button"
                disabled
              >
                Current Plan
              </button>
            ) : isCancellationPending ? (
              <button 
                className="current-plan-button"
                disabled
              >
                Active Until Period End
              </button>
            ) : (
              <button 
                className="subscribe-button"
                onClick={handleSubscribe}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Upgrade Now'}
              </button>
            )}
          </div>
        </div>

        {/* Only show cancellation section for active premium subscribers */}
        {isPremiumActive && (
          <div className="subscription-section">
            <h3>Cancel Subscription</h3>
            <p>You can cancel your subscription at any time. You'll continue to have access to premium features until the end of your billing period.</p>
            
            <button 
              className="downgrade-button"
              onClick={() => setShowCancellationConfirm(true)}
              disabled={cancellationLoading}
            >
              Cancel Subscription
            </button>
          </div>
        )}

        {/* Show period end info when cancellation is pending */}
        {isCancellationPending && subscription?.currentPeriodEnd && (
          <div className="subscription-section">
            <h3>Subscription Status</h3>
            <p>
              Your subscription will end on {new Date(subscription.currentPeriodEnd).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}. 
              You'll continue to have premium features until then.
            </p>
          </div>
        )}

        {showCancellationConfirm && (
          <div className="cancellation-confirm">
            <h3>Are you sure?</h3>
            <p>
              Your subscription will be cancelled at the end of your current billing period. 
              You'll lose access to premium features after that.
            </p>
            <div className="cancellation-actions">
              <button
                className="cancel-deny-button"
                onClick={() => setShowCancellationConfirm(false)}
                disabled={cancellationLoading}
              >
                Keep Subscription
              </button>
              <button
                className="cancel-confirm-button"
                onClick={handleCancelSubscription}
                disabled={cancellationLoading}
              >
                {cancellationLoading ? 'Cancelling...' : 'Yes, Cancel'}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SubscriptionModal; 
import React, { createContext, useContext, useState, useEffect } from 'react';
import { UserAuth } from './AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

const SubscriptionContext = createContext();

// Free tier limits
const FREE_TIER_LIMITS = {
  SCRAPES_PER_MONTH: 5,
  EMAILS_PER_MONTH: 100
};

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const { user } = UserAuth();
  const [subscription, setSubscription] = useState(null);
  const [remainingEmails, setRemainingEmails] = useState(0);
  const [remainingScrapes, setRemainingScrapes] = useState(0);
  const [freeTierLimits, setFreeTierLimits] = useState({
    EMAILS_PER_MONTH: 100,
    SCRAPES_PER_MONTH: 5
  });

  useEffect(() => {
  const checkSubscription = async () => {
    if (!user) {
      setSubscription(null);
      return;
    }

    try {
      // const response = await fetch(`http://127.0.0.1:8000/check-subscription/${user.uid}`);
      const response = await fetch(`https://api.thenodes.ca/check-subscription/${user.uid}`);
      const data = await response.json();

      if (data.success) {
        setSubscription({
          planType: data.data.planType,
          status: data.data.status,
          isSubscribed: data.data.isSubscribed,
          cancelAtPeriodEnd: data.data.cancelAtPeriodEnd,
          currentPeriodEnd: data.data.currentPeriodEnd
        });
        
        // Set remaining quotas
        setRemainingEmails(data.data.remainingEmails);
        setRemainingScrapes(data.data.remainingScrapes);
        
        // Set free tier limits
        setFreeTierLimits({
          EMAILS_PER_MONTH: data.data.emailQuota,
          SCRAPES_PER_MONTH: data.data.scrapeQuota
        });
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
    }
  };

    checkSubscription();
    
    // Poll for subscription updates every 30 seconds
    const interval = setInterval(checkSubscription, 30000);
    
    return () => clearInterval(interval);
  }, [user]);

  const value = {
    subscription,
    remainingEmails,
    remainingScrapes,
    freeTierLimits
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
} 
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import usePageTracking from './hooks/usePageTracking';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// routes
import { AuthContextProvider } from "./contexts/AuthContext";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";
import { UserAuth } from "./contexts/AuthContext";
import Landing from "./Pages/Landing";
import Home from "./Pages/Home";
import Signup from "./Pages/Signup";
import Signin from "./Pages/Signin";
import ProtectedRoute from "./Components/ProtectedRoute";
import Companies from "./Pages/Companies";
import SendingPage from "./Pages/SendingPage";
import AuthorizePage from "./Pages/Authorization";
import PopupOAuthCallback from "./Components/PopupOAuthCallback";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Components/TermsOfService";

import SubscriptionSticker from './Components/SubscriptionSticker';
import SubscriptionModal from './Components/SubscriptionModal';

// Separate component for subscription UI that has access to auth context
const SubscriptionUI = () => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { user } = UserAuth();

  if (!user) return null;

  return (
    <>
      <SubscriptionSticker onUpgradeClick={() => setShowSubscriptionModal(true)} />
      <SubscriptionModal 
        isOpen={showSubscriptionModal} 
        onClose={() => setShowSubscriptionModal(false)} 
      />
    </>
  );
};

const App = () => {
  // Initialize page tracking
  usePageTracking();

  return (
    <>
      <AuthContextProvider>
        <SubscriptionProvider>
          <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/authentication" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path="/authorization" element={<AuthorizePage />} />
            <Route
              path="/popup-oauth-callback"
              element={<PopupOAuthCallback />}
            />
            <Route
              path="/send-emails"
              element={
                <ProtectedRoute>
                  <SendingPage />
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
          <SubscriptionUI />
        </SubscriptionProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;

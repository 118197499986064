import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import "./css/SignIn.css";
import logo from "../images/logo_with_nodes.png";
import GoogleButton from "react-google-button";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const navigate = useNavigate();
  const { signIn, googleSignIn } = UserAuth();

  const isGmailAddress = (email) => {
    return email.toLowerCase().endsWith("@gmail.com");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setWarning("");

    if (!isGmailAddress(email)) {
      setError("Please use a Gmail address to sign in.");
      return;
    }

    try {
      const result = await signIn(email, password);
      if (result === true) {
        navigate("/home");
      } else if (
        result.includes("password") ||
        result.includes("user-not-found")
      ) {
        setWarning(
          "It looks like you may have signed up with Google. Please try using the 'Sign in with Google' button below."
        );
      } else {
        setError(result);
      }
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError("");
      setWarning("");
      const result = await googleSignIn();
      if (result === true) {
        navigate("/home");
      } else if (result.includes("Please sign up first")) {
        setWarning(
          "You need to create an account and accept the terms of service first. " +
          "Please click 'Sign up' above to get started."
        );
      } else {
        setError(result);
      }
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  return (
    <div className="signin-container">
      <img src={logo} alt="Logo" className="logo" />
      <div className="signin-card">
        <h1 className="signin-title">Sign In</h1>
        <p className="signin-subtitle">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
        {error && <div className="error-message">{error}</div>}
        {warning && <div className="warning-message">{warning}</div>}
        <form onSubmit={handleSubmit} className="signin-form">
          <div>
            <label className="signin-label">Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="signin-input"
              placeholder="Enter your Gmail"
              required
            />
          </div>
          <div>
            <label className="signin-label">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="signin-input"
              placeholder="Enter your password"
              required
            />
          </div>
          <button type="submit" className="signin-button">
            Sign In
          </button>
        </form>
        <GoogleButton
          onClick={handleGoogleSignIn}
          label="Sign in with Google"
          type="light"
          className="google-btn"
        />
      </div>
      <div className="privacy-link">
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
};

export default Signin;

import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Modal from "../Components/Modal";
import pdfToText from "react-pdftotext";

import uploadImg from '../images/upload.png'
import './css/FileUploadModal.css'

const fileTypes = ["PDF"];

const FileUploadModal = ({ user, isOpen, onClose, setMessage, setSubject }) => {
  const [file, setFile] = useState(null);

  const handleFileUploadChange = async (file) => {
    setFile(file);
    onClose();

    try {
      const text = await pdfToText(file);
      const generateEmailTemplate = {
        userID: user.uid,
        resumeText: text,
      };

      const response = await fetch(
        "https://api.thenodes.ca/ai-generate-email-template",
        // "http://127.0.0.1:8000/ai-generate-email-template",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(generateEmailTemplate),
        }
      );

      const emailTemplate = await response.json();
      setMessage(emailTemplate.data.message);
      setSubject(emailTemplate.data.subject);

    } catch (e) {
      console.error("Error reading PDF:", e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className = 'file-mask'>
        <div className = 'mask'>
          <img src ={uploadImg}></img>
          <p>upload resume here</p>
        </div>
      <FileUploader
        handleChange={handleFileUploadChange}
        name="file"
        types={fileTypes}
        />
      </div>
    </Modal>
  );
};

export default FileUploadModal;

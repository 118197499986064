import React, { useState, useContext, createContext, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, firestore } from "../firebase.js";
import { doc, getDoc, setDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // Initialize user state from localStorage if available
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  const createUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // console.log("New user UserID: ", user.uid);

      // Create user document
      await setDoc(doc(firestore, "Users", user.uid), {
        email: email,
        scraped_results: {},
        emails_sent: [],
        premium: false,
        userID: user.uid,
      });
      // console.log("User document written with ID: ", user.uid);

      // Create subscriber document with free tier
      await setDoc(doc(firestore, "Subscribers", user.uid), {
        currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days from now
        planType: "free",
        status: "active",
        cancelAtPeriodEnd: false,
        scrapeUsed: 0,
        emailsUsed: 0,
        dailyScrapeUsed: 0,
        lastResetDate: new Date(),
        lastDailyReset: new Date(),
        lastUpdated: new Date(),
        stripeCustomerId: "",
        subscriptionId: "",
        userId: user.uid
      });
      // console.log("Subscriber document written with ID: ", user.uid);

      return true;
    } catch (error) {
      const errorMessage = error.message;
      // console.log("Error creating user: ", errorMessage);
      return errorMessage;
    }
  };

  const signIn = async (email, password) => {
    try {
      // console.log(email, password);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // console.log("UserID at signin: ", user.uid);

      // Check if user document exists
      const userRef = doc(firestore, "Users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // Sign out the user since they don't have an account
        await signOut(auth);
        return "No account found. Please sign up first.";
      }

      return true;
    } catch (error) {
      const errorMessage = error.message;
      // console.log("Error signing in user: ", errorMessage);
      if (errorMessage.includes("auth/user-not-found")) {
        return "No account found. Please sign up first.";
      }
      return errorMessage;
    }
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(firestore, "Users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // Instead of creating a new account, sign out and return a message
        await signOut(auth);
        return "Please sign up first to accept the terms of service. Click 'Sign up' to create an account.";
      } else {
        console.log("Existing Google user signed in.");
      }

      return true;
    } catch (error) {
      const errorMessage = error.message;
      console.log("Error signing in with Google: ", errorMessage);
      return errorMessage;
      }
  };

  const googleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(firestore, "Users", user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        // If user already exists, sign them out and tell them to sign in instead
          await signOut(auth);
        return "You already have an account. Please sign in instead.";
        }

        // Create user document for new Google sign ups
        await setDoc(userRef, {
          email: user.email,
          scraped_results: {},
          emails_sent: [],
          premium: false,
          userID: user.uid,
        });

        // Create subscriber document with free tier
        await setDoc(doc(firestore, "Subscribers", user.uid), {
          currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
          planType: "free",
          status: "active",
          cancelAtPeriodEnd: false,
          scrapeUsed: 0,
          emailsUsed: 0,
          dailyScrapeUsed: 0,
          lastResetDate: new Date(),
          lastDailyReset: new Date(),
          lastUpdated: new Date(),
          stripeCustomerId: "",
          subscriptionId: "",
          userId: user.uid
        });

      return true;
    } catch (error) {
      const errorMessage = error.message;
      console.log("Error signing up with Google: ", errorMessage);
      return errorMessage;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");  // Clear user from localStorage
      return true;
    } catch (error) {
      console.error("Error logging out:", error);
      return false;
    }
  };

  useEffect(() => {
    // console.log("Setting up auth state listener");
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // console.log("Auth state changed:", currentUser?.uid);
      
      if (currentUser) {
        // Store user in localStorage and state
        localStorage.setItem("user", JSON.stringify(currentUser));
        setUser(currentUser);
      } else {
        // Clear user from localStorage and state
        localStorage.removeItem("user");
        setUser(null);
      }
      
      setLoading(false);
    });

    return () => {
      console.log("Cleaning up auth state listener");
      unsubscribe();
    };
  }, []);

  // Provide auth state and loading status
  const value = {
    user,
    loading,
    createUser,
    signIn,
    googleSignIn,
    googleSignUp,
    logout
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};

import React from "react";
import "./css/SelectedDataDropdown.css";
import dropdownIcon from "../images/dropdown.png";

const Dropdown = ({ data, handleDropdownChange, currentDatasetSelected }) => {
  return (
    <div className="dropdown-container">
      <h3 className="send-title">Send emails to:</h3>
      <div className="dropdown-wrapper">
        <select
          className="dropdown-selection"
          value={currentDatasetSelected}
          onChange={handleDropdownChange}
          title={currentDatasetSelected}
        >
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <option key={index} value={item} title={item}>
                {item}
              </option>
            ))
          ) : (
            <option>No datasets available</option>
          )}
        </select>
        <img src={dropdownIcon} alt="" className="dropdown-icon" />
      </div>
    </div>
  );
};

export default Dropdown;

import React, { useState } from 'react';
import { useSubscription } from '../contexts/SubscriptionContext';
import './css/SubscriptionSticker.css';
import SubscriptionModal from './SubscriptionModal';

const SubscriptionSticker = () => {
  const { subscription } = useSubscription();
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const isPremium = subscription?.planType === 'premium' && subscription?.status === 'active';

  return (
    <>
      <div 
        className={`subscription-sticker ${isPremium ? 'premium' : ''}`}
        onClick={handleClick}
      >
        {isPremium ? (
          <>
            <span>Premium Member</span>
            {subscription?.cancelAtPeriodEnd && (
              <span className="cancellation-pending">Cancellation Pending</span>
            )}
          </>
        ) : (
          <>
            <span>Upgrade to Premium ✨</span>
            <span className="shine"></span>
          </>
        )}
      </div>

      <SubscriptionModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default SubscriptionSticker; 
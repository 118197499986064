import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";

// authentication checker
const ProtectedRoute = ({ children }) => {
  const auth = UserAuth();

  // if user doesnt exist, redirect to authentication
  if (!auth || !auth.user) {
    return <Navigate to="/authentication" />;
  }

  // else return children
  return children;
};

export default ProtectedRoute;

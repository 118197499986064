// Encryption key should match the one in backend .env
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

// Debug log to check environment variable loading
// console.log('Available env variables:', Object.keys(process.env).filter(key => key.startsWith('REACT_APP_')));
// console.log('Encryption key loaded:', ENCRYPTION_KEY ? 'Yes' : 'No');

/**
 * Convert string to Uint8Array
 */
function stringToUint8Array(str) {
    // Normalize spaces to regular ASCII spaces
    str = str.replace(/\s+/g, ' ');
    const encoder = new TextEncoder();
    // console.log('Converting to Uint8Array:', str);
    const encoded = encoder.encode(str);
    // console.log('Encoded bytes:', Array.from(encoded));
    return encoded;
}

/**
 * Base64 encode with URL-safe alphabet
 */
function base64URLEncode(buffer) {
    // Convert the buffer to a binary string
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    
    // Base64 encode and make URL safe
    const encoded = btoa(binary)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    // console.log('Base64URL encoded:', encoded);
    return encoded;
}

/**
 * Encrypt app code using a format compatible with backend
 */
export async function encryptAppCode(appCode) {
    if (!appCode) {
        throw new Error('App code is missing');
    }

    try {
        // console.log('=== App Code Encryption Process ===');
        // console.log('1. Input app code:', appCode);
        
        // Normalize spaces before encoding
        appCode = appCode.trim().replace(/\s+/g, ' ');
        
        const binaryData = stringToUint8Array(appCode);
        // console.log('2. Binary data length:', binaryData.length, 'bytes');
        
        // Base64URL encode the binary data
        const result = base64URLEncode(binaryData);
        // console.log('3. Final encrypted result:', result);
        // console.log('=== Encryption Complete ===');
        
        return result;
    } catch (error) {
        console.error('Encryption error:', error);
        throw new Error('Failed to encrypt app code');
    }
} 
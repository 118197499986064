import React from 'react';
import { formatDate } from '../utils/dateUtils';
import './css/SentRangesWarning.css';

const SentRangesWarning = ({ overlappingRanges, isDatasetComplete }) => {
  if (isDatasetComplete) {
    return (
      <div className="warning-inline dataset-complete">
        <span className="warning-icon">✅</span>
        <div className="warning-text">
          All emails in this dataset have been sent
        </div>
      </div>
    );
  }

  if (!overlappingRanges?.length) return null;
  
  // Sort ranges by start index
  const sortedRanges = [...overlappingRanges].sort((a, b) => a.range_start - b.range_start);
  
  return (
    <div className="warning-inline">
      <span className="warning-icon">⚠️</span>
      <div className="warning-text">
        Previously sent to: 
        {sortedRanges.map((range, idx) => (
          <span key={idx} className="warning-range">
            {range.range_start + 1}-{range.range_end + 1}
            <span className="warning-date">{formatDate(range.date_sent)}</span>
            {idx < sortedRanges.length - 1 && <span className="warning-separator">•</span>}
          </span>
        ))}
      </div>
    </div>
  );
};

export default SentRangesWarning; 
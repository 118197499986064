import React, { useState, useEffect } from "react";
import Dropdown from "../Components/SelectedDataDropdown";
import { UserAuth } from "../contexts/AuthContext";
import { useSubscription } from "../contexts/SubscriptionContext";
import SendEmails from "./SendEmails";
import "./css/SendingPage.css";
import RangeSelector from '../Components/RangeSelector';
import ResultEntry from '../Components/ResultEntry';
import SentRangesWarning from '../Components/SentRangesWarning';
import SubscriptionModal from '../Components/SubscriptionModal';
import { encryptAppCode } from '../utils/encryption';
import FileUploadModal from "../Components/FileUploadModal";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', { 
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

// Warning banner for exceeding quota
const QuotaWarning = ({ remainingEmails, batchSize, onUpgradeClick, isPremium }) => {
  // Don't show warning for premium users or if they have enough quota
  if (isPremium || remainingEmails >= batchSize) return null;
  
  return (
    <div 
      className="warning-inline quota-warning"
      onClick={onUpgradeClick}
      style={{ cursor: 'pointer' }}
    >
      <span className="warning-icon">⚠</span>
      This will exceed the number of emails under the free version, consider <u>upgrading here</u>
    </div>
  );
};

const SendingPage = ({ scrapedData, datasets_sent, scrapedDatasetNames }) => {
  const { user } = UserAuth();
  const { subscription, remainingEmails } = useSubscription();
  
  const [selectedOption, setSelectedOption] = useState("None");
  const [emailData, setEmailData] = useState([]);
  const [emailsSentSuccessfully, setEmailsSentSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alreadySentWarning, setAlreadySentWarning] = useState(false);
  const [isDatasetComplete, setIsDatasetComplete] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [message, setMessage] = useState(`Hi [name],

I hope this email finds you well. My name is ______, and I am currently in my third year at Ivey business school, completing a dual degree with computer science. I'm interested in hearing about your experiences at [company].

A quick summary about me:
  1. 
  2. 
  3.

If you're willing, I would appreciate the opportunity to learn more about your experiences through a call. I am more than happy to work around your schedule.


Best regards,

Your Name`);
  const [subject, setSubject] = useState("");
  const [appCode, setAppCode] = useState("");

  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [sentRanges, setSentRanges] = useState([]);

  const [removedIndices, setRemovedIndices] = useState(new Set());
  const [overlappingRanges, setOverlappingRanges] = useState([]);

  const [isSending, setIsSending] = useState(false);

  // Calculate total people that would be contacted
  const calculateTotalPeople = () => {
    if (!emailData || rangeStart === rangeEnd) return 0;
    
    const visibleData = emailData
      .filter((_, index) => !removedIndices.has(index))
      .slice(rangeStart, rangeEnd);
    
    return visibleData.length; // Each entry represents one unique person
  };

  const totalPeopleToContact = calculateTotalPeople();
  const isPremium = subscription?.planType === 'premium' && subscription?.status === 'active';
  const showQuotaWarning = !isPremium && selectedOption !== "None" && totalPeopleToContact > remainingEmails;

  const getQuotaDisplay = () => {
    if (isPremium) {
      return 'Unlimited contacts available';
    }
    const totalEmailFormats = emailData
      .filter((_, index) => !removedIndices.has(index))
      .slice(rangeStart, rangeEnd)
      .reduce((total, person) => total + person.email_formats.length, 0);
    
    return `${remainingEmails} contacts remaining this month${totalPeopleToContact > 0 ? 
      ` (This batch will contact ${totalPeopleToContact} people with ~${totalEmailFormats} email formats)` : ''}`;
  };

  // Handle subscription modal close with refresh
  const handleModalClose = () => {
    setShowSubscriptionModal(false);
    setShowFileUploadModal(false);
    // Refresh the page to update subscription status
    window.location.reload();
  };

  // Add function to check if dataset is complete
  const checkDatasetCompletion = (ranges, totalCount) => {
    if (!ranges?.length || !totalCount) return false;

    // Sort ranges by start index
    const sortedRanges = [...ranges].sort((a, b) => a.range_start - b.range_start);
    
    // Create a Set of all sent indices
    const sentIndices = new Set();
    sortedRanges.forEach(range => {
      for (let i = range.range_start; i <= range.range_end; i++) {
        sentIndices.add(i);
      }
    });

    // Check if all indices from 0 to totalCount-1 are in the set
    return sentIndices.size === totalCount;
  };

  const handleDropdownChange = (e) => {
    e.preventDefault();
    const datasetName = e.target.value;
    setSelectedOption(datasetName);

    // Reset all states when switching datasets
    setRangeStart(0);
    setRemovedIndices(new Set());
    setOverlappingRanges([]);
    setAlreadySentWarning(false);
    setIsDatasetComplete(false);
    
    const selectedDataset = scrapedData[datasetName];
    
    if (selectedDataset) {
      setEmailData(selectedDataset);
      setRangeEnd(selectedDataset.length);
      
      // Get sent ranges for this dataset
      const datasetInfo = datasets_sent.find(d => d.name === datasetName);
      
      if (datasetInfo) {
        setSentRanges(datasetInfo.sent_ranges);
        // Check if dataset is complete
        setIsDatasetComplete(checkDatasetCompletion(datasetInfo.sent_ranges, selectedDataset.length));
      } else {
        setSentRanges([]);
      }
    } else {
      setEmailData([]);
      setRangeEnd(0);
      setSentRanges([]);
    }
  };

  const handleRemoveRecipient = (originalIndex) => {
    setRemovedIndices(current => {
      const updated = new Set(current);
      updated.add(originalIndex);
      return updated;
    });
  };

  useEffect(() => {
    if (selectedOption && scrapedData) {
      const selectedDataset = scrapedData[selectedOption];
      if (selectedDataset) {
        setEmailData(selectedDataset);
      }
    }
  }, [selectedOption, scrapedData]);

  useEffect(() => {
    if (selectedOption && emailData.length > 0) {
      setRangeEnd(emailData.length);
      
      const datasetInfo = datasets_sent.find(d => d.name === selectedOption);
      if (datasetInfo) {
        setSentRanges(datasetInfo.sent_ranges);
      }
    }
  }, [selectedOption, emailData, datasets_sent]);

  useEffect(() => {
    if (selectedOption && selectedOption !== "None" && sentRanges.length > 0) {
      // Check for overlaps for warning purposes
      const overlapping = sentRanges.filter(range => 
        (rangeStart >= range.range_start && rangeStart <= range.range_end) ||
        (rangeEnd >= range.range_start && rangeEnd <= range.range_end) ||
        (range.range_start >= rangeStart && range.range_start <= rangeEnd) ||
        (range.range_end >= rangeStart && range.range_end <= rangeEnd)
      );
      
      // Set all sent ranges for display
      setOverlappingRanges(sentRanges);  // Show all sent ranges
      setAlreadySentWarning(true);  // Always show warning if there are sent ranges
    } else {
      setOverlappingRanges([]);
      setAlreadySentWarning(false);
    }
  }, [selectedOption, sentRanges, rangeStart, rangeEnd]);

  const handleSendEmails = async () => {
    // Get visible data for sending
    const visibleData = emailData
      .filter((_, index) => !removedIndices.has(index))
      .slice(rangeStart, rangeEnd);
    
    // Check if free tier user has enough quota
    if (!isPremium && visibleData.length >= remainingEmails) {
      setShowSubscriptionModal(true);
      return;
    }

    setIsSending(true);

    try {
      // Encrypt the app code
      const encryptedAppCode = await encryptAppCode(appCode);

      // Get visible data (slice is exclusive of end)
      const userEmailDetails = {
        userID: user.uid,
        originalData: emailData,
        filteredData: visibleData,
        message,
        subject,
        datasetName: selectedOption,
        app_code: encryptedAppCode,
        range_start: rangeStart,
        range_end: rangeEnd - 1,  // Adjust to be inclusive for backend
        removed_indices: Array.from(removedIndices)
      };

      // Show sending overlay
      const overlay = document.createElement("div");
      overlay.className = "sending-overlay";
      overlay.innerHTML = `
        <div class="sending-content">
          <div class="sending-spinner"></div>
          <h2>Sending Emails...</h2>
          <p>This may take a few minutes as we're sending emails in batches to respect Gmail's limits.</p>
          <p>You can navigate away from this page. We'll notify you when the emails are sent.</p>
        </div>
      `;
      document.body.appendChild(overlay);

      const response = await fetch("https://api.thenodes.ca/send-emails", {
      // const response = await fetch("http://127.0.0.1:8000/send-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userEmailDetails),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.detail || 'Failed to send emails');
      }

      setEmailsSentSuccessfully(true);
      
      // Update sent ranges (adjust range_end to be inclusive)
      const newRange = {
        range_start: rangeStart,
        range_end: rangeEnd - 1,  // Adjust to be inclusive for display
        date_sent: new Date().toISOString()
      };
      setSentRanges(prev => [...prev, newRange]);
      
      // Check if dataset is now complete
      setIsDatasetComplete(checkDatasetCompletion(
        [...sentRanges, newRange],
        emailData.length
      ));

      // Remove sending overlay
      const existingOverlay = document.querySelector(".sending-overlay");
      if (existingOverlay) {
        document.body.removeChild(existingOverlay);
      }

      // Show success/partial success message
      const successMessage = document.createElement("div");
      successMessage.className = "success-message";
      
      if (responseData.failed_sends && responseData.failed_sends.length > 0) {
        successMessage.innerHTML = `
          <div class="message-content">
            <div class="message-header">
              <span class="message-title">⚠️ Emails Partially Sent</span>
              <button class="close-button" onclick="this.parentElement.parentElement.parentElement.remove()">×</button>
            </div>
            <div class="message-body">
              <strong>${responseData.message}</strong><br><br>
              Some emails were not sent due to Gmail's rate limits. Please wait a few minutes and try sending to the failed recipients again.
            </div>
          </div>
        `;
      } else {
        successMessage.innerHTML = `
          <div class="message-content">
            <div class="message-header">
              <span class="message-title">✅ Emails Sent Successfully!</span>
              <button class="close-button" onclick="this.parentElement.parentElement.parentElement.remove()">×</button>
            </div>
            <div class="message-body">
              <strong>All emails have been sent successfully!</strong><br><br>
              Please check your inbox for sent emails.
            </div>
          </div>
        `;
      }
      
      document.body.appendChild(successMessage);

    } catch (e) {
      console.error("Error sending emails:", e);
      const errorMessage = document.createElement("div");
      errorMessage.className = "error-message";
      errorMessage.innerHTML = `
        <div class="message-content">
          <div class="message-header">
            <span class="message-title">❌ Error Sending Emails</span>
            <button class="close-button" onclick="this.parentElement.parentElement.parentElement.remove()">×</button>
          </div>
          <div class="message-body">
            ${e.message || "Failed to send emails. Please try again."}
          </div>
        </div>
      `;
      document.body.appendChild(errorMessage);

      // Remove sending overlay on error
      const overlay = document.querySelector(".sending-overlay");
      if (overlay) {
        document.body.removeChild(overlay);
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <FileUploadModal
        user={user}
        isOpen={showFileUploadModal}
        onClose={() => setShowFileUploadModal(false)}
        setMessage={setMessage}
        setSubject={setSubject}
      />
          
      {showQuotaWarning && (
        <div className="warning-inline quota-warning" onClick={() => setShowSubscriptionModal(true)} style={{ cursor: 'pointer' }}>
          <span className="warning-icon">⚠</span>
          This will exceed the number of contacts under the free version, consider <u>upgrading here</u>
        </div>
      )}
      <div id="sending-page" className={isSending ? 'sending' : ''}>
        <div className="page-header">
          <Dropdown
            data={scrapedDatasetNames}
            currentDatasetSelected={selectedOption}
            handleDropdownChange={handleDropdownChange}
            disabled={isSending}
          />
          <button className = 'ai-generate-button fancy-button' onClick={() => setShowFileUploadModal(true)}>AI Generate Email Template</button>
          <div className="quota-info">
            <div className="quota-status">
              <span className="remaining-count">
                {getQuotaDisplay()}
              </span>
            </div>
            {(alreadySentWarning || isDatasetComplete) && (
              <SentRangesWarning 
                overlappingRanges={overlappingRanges}
                isDatasetComplete={isDatasetComplete}
              />
            )}
          </div>
        </div>

        <div className="send-emails-portion-container">
          <SendEmails
            setMessage={setMessage}
            setSubject={setSubject}
            subject={subject}
            message={message}
            appCode={appCode}
            setAppCode={setAppCode}
            handleSendEmails={handleSendEmails}
            isLoading={isSending}
            disabled={isSending}
          />

          {/* <div className="recipient-container"> */}
            <div className="selected-recipients-list">
              <h3>Selected Recipients:</h3>
              {selectedOption !== "None" && (
                <RangeSelector
                  totalCount={emailData.length}
                  rangeStart={rangeStart}
                  setRangeStart={setRangeStart}
                  rangeEnd={rangeEnd}
                  setRangeEnd={setRangeEnd}
                  disabled={isSending}
                />
              )}
              <div className="recipients-list">
                {/* { emailData.splice(rangeStart, rangeEnd).length === 0 ? 
                <div className = 'empty-email-data'>no recipients | இ௰இ</div> : */
                emailData
                  .slice(rangeStart, rangeEnd)
                  .map((value, index) => {
                    const originalIndex = index + rangeStart;
                    if (removedIndices.has(originalIndex)) {
                      return null;
                    }
                    return (
                      <ResultEntry
                        key={originalIndex}
                        name={value.name}
                        company={value.company}
                        profile_link={value.profile_link}
                        profile_img={value.profile_image}
                        onRemove={() => handleRemoveRecipient(originalIndex)}
                        disabled={isSending}
                      />
                    );
                  })
                  .filter(Boolean)
                }
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>

      <SubscriptionModal 
        isOpen={showSubscriptionModal}
        onClose={handleModalClose}
        feature="email"
      />
    </>
  );
};

export default SendingPage;

import React from "react";
import { Link } from "react-router-dom";
// import logo from '../images/logo.png'
import logo from '../images/logo_with_nodes.png'
import { UserAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import "./css/NavBar.css"; // Import your NavBar-specific CSS

const NavBar = ({profile_image, contact, get_started}) => {
  const { user, logout } = UserAuth();

  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const handlePremium = () => {
    const subscription_sticker = document.querySelector('.subscription-sticker')
    console.log(subscription_sticker)
    if (subscription_sticker) subscription_sticker.click()
  }

  const redirectHome = () => {
    // navigate("/home")
    navigate("/")
  }

  const toggleDropdown = () => {
    document.getElementById("profile-dropdown").classList.toggle("active");
  }

  return (
    <nav>
      <img src = {logo} className = 'logo' onClick={redirectHome}></img>
      <div className="navbar-container">
        <ul>
          {/* <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/companies">Companies</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li> */}
          {/* <li>
            <Link to="/authentication">Login/Sign Up</Link>
          </li> */}
          {contact ? <li> <Link to="mailto:ansapplication@gmail.com" target = '_blank' style={{ fontSize: '18px' }}><strong>Contact Us</strong></Link> </li> : null}

          {get_started ? 
          <Link to="/home"> <button> {profile_image ? 'Dashboard' : 'Get Started'} </button> </Link> : null}
          {profile_image ? <li id = 'profile-icon'> 
            
              <img src = {profile_image} alt = 'profile' onClick={toggleDropdown}></img>

              <div id="profile-dropdown">
                <button onClick = {handlePremium}>Upgrade to Premium ✨</button>
                <button id = 'logout-btn' onClick={handleLogout}>Logout</button>
              </div>

          </li> 
          : null
          }
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;

import React from "react";
import NavBar from "../Components/NavBar";

import ChromeIcon from "../images/chrome.png";
import stars from '../images/stars.png'

import ext0 from '../images/ext0.png'
import ext1 from '../images/ext1.png'

// import snippetVideo from '../images/NodesScrape.gif'
import snippetVideo from '../assets/NodesScrape.mp4'

import ImageCarousel from "../Components/LandingPageImageCarousel"; // Import the carousel
import "./css/Landing.css"; // Import the Landing page CSS
import { Link } from "react-router-dom";

import {useEffect, useState} from 'react'
import { UserAuth } from "../contexts/AuthContext";

import xIcon from '../images/x.svg';
import instagramIcon from '../images/instagram.svg';
import linkedinIcon from '../images/linkedin.svg';
import tiktokIcon from '../images/tiktok.svg';

const Landing = () => {

  const {user, logout} = UserAuth()

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section id="Landing">
      <NavBar 
        contact = {true} 
        get_started={true}
        profile_image = {user ? user.photoURL : null}
      />
      {/* Apply gradient and larger size to h1 */}
      
      <div className = 'landing-text'>
        {/* <h1>Unleash your</h1>
        <h1>Networking Potential</h1> */}
        <h1>Click. Email. Internship.</h1>

        <p>Nodes makes sourcing emails on LinkedIn fast and easy. Connect, grow, and scale effortlessly.</p>


        <div className="rating">
          <img src ={stars}></img>
          <img src ={stars}></img>
          <img src ={stars}></img>
          <img src ={stars}></img>
          <img src ={stars}></img>
          <p>rated 5 stars</p>
        </div>

        <Link to = 'https://chromewebstore.google.com/detail/nodes/efdjbolloapdcnphaapdklojjenlgdbo' target = '_blank'> 
          <button>
            <img src = {ChromeIcon}></img>
            <strong>Add to Chrome&nbsp;</strong> - it's free
          </button> 
        </Link>

      </div>

      <div className = 'extension-example'>
        <div className="example" id ='ext0' 
          style={{ 
            transform: `translateY(calc(-50% + ${scrollY * -0.5}px)) rotate(${-25 + scrollY * 0.1}deg)`,
          }}
          > <img src ={ext0}></img></div>

        <div className="example" id ='ext1'
          style={{ 
            transform: `translateY(calc(-50% + ${scrollY * -0.5}px)) rotate(${25 + scrollY * -0.1}deg)`,
          }}
        > <img src ={ext1}></img></div>
      </div>

      <div className ='snippet'>
        {/* <img src = {snippetVideo}></img> */}
        <video src = {snippetVideo} loop muted playsInline autoPlay></video>
      </div>
      

      {/* <ImageCarousel />  */}
      {/* Add the ImageCarousel component here */}
      {/* Terms and Privacy links side by side */}



      <div className="bottom-left">
        <Link className="privacy-link" to="/terms-of-service">
          Terms of Service
        </Link>
        <Link to="/privacy-policy" className="privacy-link">
          Privacy Policy
        </Link>
      </div>

      <div className="sticky-social">
      <a href="https://www.instagram.com/get_nodes" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" />
        </a>
        <a href="https://twitter.com/get_nodes" target="_blank" rel="noopener noreferrer">
          <img src={xIcon} alt="X (Twitter)" />
        </a>
        <a href="https://www.tiktok.com/@get_nodes" target="_blank" rel="noopener noreferrer">
          <img src={tiktokIcon} alt="TikTok" />
        </a>
        <a href="https://www.linkedin.com/company/thenodes" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" />
        </a>
      </div>
    </section>
  );
};

export default Landing;

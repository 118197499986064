// Ensure gtag is available
const gtag = window.gtag;

// Track page views
export const pageView = (page_path) => {
  if (gtag) {
    gtag('config', 'G-7W1VDGP2YM', {
      page_path: page_path
    });
  }
};

// Track events
export const event = ({ action, category, label, value }) => {
  if (gtag) {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
}; 
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageView } from '../utils/analytics';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    pageView(location.pathname);
  }, [location]);
}

export default usePageTracking; 